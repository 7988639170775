<template>
  <div>
    <div class="box">
      <div class="main">
        <div class="search">
          <el-button type="primary" @click="addRole"
            >+ 新建角色</el-button
          >
        </div>
        <div class="primary">
          <el-table
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%"
            max-height="600px"
            :header-cell-style="{
            color: '#333333',
            backgroundColor: '#FBFBFD',
          }"
            row-key="id"
            border
            lazy
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="50" align="center">
            </el-table-column>
            <el-table-column
              type="index"
              label="序号"
              width="60"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="RoleName"
              label="角色名称"
              min-width="100"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="HospitalName"
              label="采购组织"
              min-width="150"
              align="center"
            >
            </el-table-column>
            <el-table-column label="操作" width="150" align="center">
              <template slot-scope="scope">
                <el-button v-show="scope.row.Flag==1?true:false"
                  type="text"
                  style="margin-right: 10px;font-size: 16px;color: #3978E7;font-weight: 400;"
                  @click="showEditDialog(scope.row)"
                  >编辑</el-button
                >
                <el-button
                  v-show="scope.row.Flag==1?true:false"
                  type="text"
                  style="font-size: 16px;color: #3978E7;font-weight: 400;"
                  @click="showDelById(scope.row)"
                  >删除</el-button
                >
                 <el-button v-show="scope.row.Flag==0?true:false"
                  type="text"
                  style="margin-right: 10px;font-size: 16px;color: #3978E7;font-weight: 400;"
                  @click="showEditDialog(scope.row)"
                  >查看</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="block">
            <el-pagination
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="queryInfo.PageIndex"
              :page-sizes="[10, 20, 30, 40]"
              layout="total, sizes, prev, pager, next, jumper"
              :total="roleTotal"
            >
            </el-pagination>
          </div>
        </div>
      </div>
      <!-- 新建 -->
      <div class="edit-rights">
        <el-dialog
          :visible.sync="addDialogVisible"
          width="1050px"
          top="5vh"
          v-dialogDrag
        >
          <div slot="title" class="header-title">
            <span class="title-name"></span>
            <span class="title-age">新建角色权限</span>
          </div>
          <el-tabs v-model="activeName">
            <el-tab-pane label="科研事务" name="2">
              <div class="check_role">
                <div class="check_title">角色名称：</div>
                <el-input
                  v-model="addRoleSelectForm.RoleInfo.RoleName"
                ></el-input>
              </div>
              <div class="check_rights">
                <div class="check_title">选择权限：</div>
                <div class="deliverySetting">
                  <div class="deliverySetting-table">
                    <div class="table-head" style="width: 870px">
                      <div class="selection" style="width: 150px">
                        <div style="width: 50px">
                          <el-checkbox
                            :indeterminate="addWorkindeterminate"
                            v-model="addWorkCheckAll"
                            @change="addHandleWorkCheckAllChange"
                          ></el-checkbox>
                        </div>
                        <div style="width: 100px;font-size: 16px">大类</div>
                      </div>
                      <div class="check_name" style="width: 720px">
                        <div class="width265" style="width: 60px">修改</div>
                        <div class="width265" style="width: 60px">保存</div>
                        <div class="width265" style="width: 60px">删除</div>
                        <div class="width265" style="width: 60px">查看</div>
                        <div class="width265" style="width: 70px">上传附件</div>
                        <div class="width265" style="width: 70px">提交保存</div>
                        <div class="width265" style="width: 60px">评价</div>
                        <div class="width265" style="width: 60px">下载</div>
                        <div class="width265" style="width: 60px">审核</div>
                        <div class="width265" style="width: 60px">锁定</div>
                        <div class="width265" style="width: 60px">关闭</div>
                        <div class="width265" style="width: 60px">重启</div>
                      </div>
                    </div>
                    <div
                      class="table-body"
                      v-for="(item, index) in addworkdistributorsInfo"
                      :key="index"
                    >
                      <div class="title_nav" style="width: 150px">
                        <div class="selection" style="width: 50px">
                          <el-checkbox
                            :indeterminate="item.indeterminate"
                            v-model="item.SelectFlag"
                            @change="
                              handleWorkCheckedCountryAllChange(
                                index,
                                item.Id,
                                $event
                              )
                            "
                            :key="index"
                          ></el-checkbox>
                        </div>
                        <div
                          class="title_left"
                          style="width: 100px; text-align: center"
                        >
                          {{ item.ResourceName }}
                        </div>
                      </div>
                      <div class="selection_all" style="width: 720px">
                        <el-checkbox
                          :disabled="el.Id==i?true:false"
                          v-for="el in item.OperList"
                          v-model="el.SelectFlag"
                          @change="
                            handleWorkCheckedCountryChange(
                              index,
                              el.Id,
                              item.Id,
                              $event
                            )
                          "
                          :key="el.Id"
                        ></el-checkbox>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="check_ground">
                <div class="check_title">数据权限范围：</div>
                <el-radio-group v-model="addRoleSelectForm.RoleInfo.SysScope" @change="oneselfchange">
                  <el-radio :label="1">本组织提交</el-radio>
                  <el-radio :label="2">本项目提交</el-radio>
                  <el-radio :label="3">本中心提交</el-radio>
                  <el-radio :label="4">本人提交</el-radio>
                </el-radio-group>
              </div>
              <div class="role-btn">
                <el-button @click="addDialogVisible = false">取消</el-button>
                <el-button @click="submintSaveWorkRolePermission">确定</el-button>
<!--                <el-button @click="addDialogVisible = false">取消</el-button>-->
              </div>
            </el-tab-pane>
          </el-tabs>
        </el-dialog>
      </div>
      <!-- 编辑 -->
      <div class="edit-rights">
        <el-dialog
          :visible.sync="editDialogVisible"
          width="1050px"
          top="5vh"
          v-dialogDrag
        >
          <div slot="title" class="header-title">
            <span class="title-name"></span>
            <span class="title-age">编辑角色权限</span>
          </div>
          <el-tabs v-model="activeNameEdit">
            <el-tab-pane label="科研事务" name="2" v-loading="loading" element-loading-text="拼命加载中"
                         element-loading-spinner="el-icon-loading"
                         element-loading-background="rgba(0, 0, 0, 0.8)">
              <div class="check_role">
                <div class="check_title">角色名称：</div>
                <el-input
                  v-model="editRoleNameValue"
                ></el-input>
              </div>
              <div class="check_rights">
                <div class="check_title">选择权限：</div>
                <div class="deliverySetting">
                  <div class="deliverySetting-table">
                    <div class="table-head" style="width: 870px">
                      <div class="selection" style="width: 150px">
                        <div style="width: 50px">
                          <el-checkbox
                            :indeterminate="editWorkindeterminate"
                            v-model="editWorkCheckAll"
                            @change="editHandleWorkCheckAllChange"
                          ></el-checkbox>
                        </div>
                        <div style="width: 100px;font-size: 16px">大类</div>
                      </div>
                      <div class="check_name" style="width: 720px">
                        <div class="width265" style="width: 60px">修改</div>
                        <div class="width265" style="width: 60px">保存</div>
                        <div class="width265" style="width: 60px">删除</div>
                        <div class="width265" style="width: 60px">查看</div>
                        <div class="width265" style="width: 70px">上传附件</div>
                        <div class="width265" style="width: 70px">提交保存</div>
                        <div class="width265" style="width: 60px">评价</div>
                        <div class="width265" style="width: 60px">下载</div>
                        <div class="width265" style="width: 60px">审核</div>
                        <div class="width265" style="width: 60px">锁定</div>
                        <div class="width265" style="width: 60px">关闭</div>
                        <div class="width265" style="width: 60px">重启</div>
                      </div>
                    </div>
                    <div
                      class="table-body"
                      v-for="(item, index) in editworkdistributorsInfo"
                      :key="index"
                    >
                      <div class="title_nav" style="width: 150px">
                        <div class="selection" style="width: 50px">
                          <el-checkbox
                            :indeterminate="item.indeterminate"
                            v-model="item.SelectFlag"
                            @change="
                              editHandleWorkCheckedCountryAllChange(
                                index,
                                item.Id,
                                $event
                              )
                            "
                            :key="index"
                          ></el-checkbox>
                        </div>
                        <div
                          class="title_left"
                          style="width: 100px; text-align: center"
                        >
                          {{ item.ResourceName }}
                        </div>
                      </div>
                      <div class="selection_all" style="width: 720px">
                        <el-checkbox
                          :disabled="el.Id==i?true:false"
                          v-for="el in item.OperList"
                          v-model="el.SelectFlag"
                          @change="
                            editHandleWorkCheckedCountryChange(
                              index,
                              el.Id,
                              item.Id,
                              $event
                            )
                          "
                          :key="el.Id"
                        ></el-checkbox>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="check_ground">
                <div class="check_title">数据权限范围：</div>
                <el-radio-group v-model="editPruceSelectValue" @change="editchange">
                  <el-radio :label="1">本组织提交</el-radio>
                  <el-radio :label="2">本项目提交</el-radio>
                  <el-radio :label="3">本中心提交</el-radio>
                  <el-radio :label="4">本人提交</el-radio>
                </el-radio-group>
              </div>
              <div class="role-btn">
                <el-button @click="editDialogVisibleWork ">取消</el-button>
                <el-button @click="submintEditWorkRolePermission" v-show="show==1?true:false"
                  >确定</el-button
                >
              </div>
            </el-tab-pane>
          </el-tabs>
        </el-dialog>
      </div>
      <div class="FinanceVoucher-dialog">
        <el-dialog
          top="35vh"
          :visible.sync="delRoleDialog"
          width="510px"
          v-dialogDrag
        >
          <div slot="title" class="header-title">
            <span class="title-name"></span>
            <span class="title-age">提示</span>
          </div>
          <div style="text-align: center">
            <i class="el-icon-warning" style="color: #FFBA00;font-size: 24px ;margin-right: 5px"></i>
            <span style="font-size: 18px;color: #666">此操作将永久删除该角色，是否继续？</span>
          </div>
          <div class="btn">
            <button  @click="delRoleDialog = false">取消</button>
            <button @click="sumDelRole">确定</button>
          </div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>
<script>
import {GetRolePermissions, SaveRolePermission, addRolePermission, getRoleResources, GetRolesList, delRolePermissions,} from "@/api/project.js";
export default {
  name: "deliverySetting",
  data() {
    return {
      PurchaseId:0,
      show:1,
      delRoleDialog: false,
      loading: true,
      queryInfo: {
        key: "",
        PageIndex: 0,
        PageSize: 8,
      },
      roleTotal: 0,
      activeName: "2",
      activeNameEdit: "2",
      addRoleSelectName: "",
      addRoleSelectValue: 1,
      addPruceSelectValue: 1,
      editPruceSelectValue: 1,
      editRoleSelectValue: 1,
      addworkdistributorsInfo: [],
      adddatadistributorsInfo: [],
      editdatadistributorsInfo: [],
      editworkdistributorsInfo: [],
      editdistributorsInfo: [],
      datadistributorsInfo: [],
      addDataCheckAll: false, //一级新增数据全选状态
      addWorkCheckAll: false, //一级新增事务全选状态
      editDataCheckAll: false, //一级编辑数据全选状态
      editWorkCheckAll: false, //一级编辑事务全选状态
      addDataindeterminate: false,
      addWorkindeterminate: false,
      editDataindeterminate: false,
      editWorkindeterminate: false,
      checkData: [],
      checkAll: true,
      checkedValue: false,
      editRoleNameValue: "",
      addRoleOptions: [],
      editRoleOptions: [],
      resourcesMenuList: [],
      currentPage4: 4,
      addRoleItemList: {
        roleId: "",
      },
      checkRoleItemList: {
        RoleId: "",
        SysId: "2",
      },
      RolePermissionForm: {
        ResourceId: "",
        Oper1: "",
        Oper2: "",
        Oper3: "",
        Oper4: "",
        Oper5: "",
        Oper6: "",
        Oper7: "",
        Oper8: "",
        Oper9: "",
        Oper10: "",
        Oper11: "",
        Oper12: "",
      },

      addRoleSelectForm: {
        RoleInfo: {
          RoleName: "",
          SysId: 1,
          SysScope: 1,
        },
        RolePermissions: [],
      },
      editRoleSelectForm: {
        RoleInfo: {
          Id: "",
          SysId: "",
          SysScope: "",
        },
        RolePermissions: [],
      },
      systemValueForm: {
        sysId: 1,
      },
      addTableDataArr: [],
      editTableDataArr: [],
      multipleSelection: [],
      input: "",
      researchDataCheckedList: [],
      tableData: [],
      // multipleSelection: [],
      dialogVisible: false,
      addDialogVisible: false,
      // editDialogVisible: false,
      editDialogVisible: false,
      i:"",
    };
  },
  created() {
    this.getSystemRoleList();
    this.getDataSystemMenuList();
    this.getWorkSystemMenuList();
    // this.getEditSystemMenuList();
  },

  methods: {
    editchange(){
      if(this.editPruceSelectValue==4){
         this.i="9"
        //  editworkdistributorsInfo
        this.editworkdistributorsInfo.forEach(item=>{
               item.OperList.forEach(item1=>{
                  if(item1.Id=="9"){
                    item1.SelectFlag=false
                  }
               })
            
            item.indeterminate=item.OperList.some(item2=>{
                   return item2.SelectFlag==true
               })
          })   
      }else{
        this.i=""
      }
    },
    oneselfchange(){
      if(this.addRoleSelectForm.RoleInfo.SysScope==4){
          this.i="9"
          this.addworkdistributorsInfo.forEach(item=>{
               item.OperList.forEach(item1=>{
                  if(item1.Id=="9"){
                    item1.SelectFlag=false
                  }
               })
            
            item.indeterminate=item.OperList.some(item2=>{
                   return item2.SelectFlag==true
               })
          })
      }else{
          this.i=""
      }
       console.log(this.addRoleSelectForm.RoleInfo.SysScope);
    },
    addRole() { //添加角色
      this.i=""
      this.addDialogVisible = true
      this.addRoleSelectForm.RoleInfo.SysScope = 1
      // this.editWorkCheckAll = false
    },
    editDialogVisibleWork() {
      this.editDialogVisible = false
    },
    inquireSumbintRoler() {
      this.getUserList();
    },
    async getDataSystemMenuList() {
      const parame = {};
      parame.SysId = 1;
      let res = await getRoleResources(parame);
      // console.log(res);
      if (res.data.Status !== 1) return this.$message.error("获取失败！");
      res.data.Entity.resources.forEach((el) => {
        el.SelectFlag = false;
        el.isIndeterminate = false;
        let Operlist = [
          {
            Id: "1",
            SelectFlag: false,
          },
          {
            Id: "2",
            SelectFlag: false,
          },
          {
            Id: "3",
            SelectFlag: false,
          },
          {
            Id: "4",
            SelectFlag: false,
          },
          {
            Id: "5",
            SelectFlag: false,
          },
          {
            Id: "6",
            SelectFlag: false,
          },
          {
            Id: "7",
            SelectFlag: false,
          },
          {
            Id: "8",
            SelectFlag: false,
          },
          {
            Id: "9",
            SelectFlag: false,
          },
          {
            Id: "10",
            SelectFlag: false,
          },
          {
            Id: "11",
            SelectFlag: false,
          },
          {
            Id: "12",
            SelectFlag: false,
          },
        ];
        el.OperList = Operlist;
        res.data.Entity.resources.forEach((item) => {
          for (var key in item) {
            if (
              key == "ParentId" ||
              key == "ResourceType" ||
              key == "UrlAction"
            ) {
              delete item[key];
            }
          }
        });
      });
      this.adddatadistributorsInfo = res.data.Entity.resources;
      this.editdatadistributorsInfo = res.data.Entity.resources;
      // this.editdatadistributorsInfo =JSON.parse(JSON.stringify(res.data.Entity.resources))
    },
    async getWorkSystemMenuList() {
      const parame = {};
      parame.SysId = 2;
      let res = await getRoleResources(parame);
      // console.log(res);
      if (res.data.Status !== 1) return this.$message.error("获取失败！");
      res.data.Entity.resources.forEach((el) => {
        el.SelectFlag = false;
        el.isIndeterminate = false;
        let Operlist = [
          {
            Id: "1",
            SelectFlag: false,
          },
          {
            Id: "2",
            SelectFlag: false,
          },
          {
            Id: "3",
            SelectFlag: false,
          },
          {
            Id: "4",
            SelectFlag: false,
          },
          {
            Id: "5",
            SelectFlag: false,
          },
          {
            Id: "6",
            SelectFlag: false,
          },
          {
            Id: "7",
            SelectFlag: false,
          },
          {
            Id: "8",
            SelectFlag: false,
          },
          {
            Id: "9",
            SelectFlag: false,
          },
          {
            Id: "10",
            SelectFlag: false,
          },
          {
            Id: "11",
            SelectFlag: false,
          },
          {
            Id: "12",
            SelectFlag: false,
          },
        ];
        el.OperList = Operlist;
        res.data.Entity.resources.forEach((item) => {
          for (var key in item) {
            if (
              key == "ParentId" ||
              key == "ResourceType" ||
              key == "UrlAction"
            ) {
              delete item[key];
            }
          }
        });
      });
      this.addworkdistributorsInfo = res.data.Entity.resources;
      this.editworkdistributorsInfo = res.data.Entity.resources;
      console.log(this.editdistributorsInfo);
    },
    addHandleClick(event) {
      console.log(event.name);
      this.addRoleSelectForm.RoleInfo.SysId = event.name;
    },
    async editHandleClick(event) {
      this.editworkdistributorsInfo = []
      // console.log(event.name,'名字');
      this.checkRoleItemList.SysId = event.name;
      this.loading = true
      let res = await GetRolePermissions(this.checkRoleItemList);
      console.log(res,"编辑的修改问题");
      this.editPruceSelectValue  = res.data.Entity.roleEntity.SysScope
      if (res.data.Status == 1 ) {
        this.loading = false
      }
      if (res.data.Status !== 1) return this.$message.error("获取失败！");
      let oldArr = res.data.Entity.rolePermissionEntities;
      // console.log(oldArr);
      oldArr.forEach((item) => {
        // console.log(item);
        item.selected = false;
        item.isIndeterminate = false;
        item.OperList.forEach((item) => {
          if (item.SelectFlag == 1) {
            item.SelectFlag = true;
          } else {
            item.SelectFlag = false;
          }
        });
      });
      let objArr = {};  //去除数组中Id相同的对象
      oldArr = oldArr.reduce((cur,next) => {
        objArr[next.ResourceId] ? "" : objArr[next.ResourceId] = true && cur.push(next);
        return cur;
      },[])
      oldArr.forEach((el) => {
        // console.log(el);
        for (var key in el) {
          if (
            key == "Id" ||
            key == "RoleId" ||
            key == "Oper1" ||
            key == "Oper2" ||
            key == "Oper3" ||
            key == "Oper4" ||
            key == "Oper5" ||
            key == "Oper6" ||
            key == "Oper7" ||
            key == "Oper8" ||
            key == "Oper9" ||
            key == "Oper10" ||
            key == "Oper11" ||
            key == "Oper12"
          ) {
            delete el[key];
          }
          // console.log(oldArr);
          // this.editworkdistributorsInfo = []
          this.editworkdistributorsInfo = JSON.parse(
            JSON.stringify(oldArr).replace(/ResourceId/g, "Id")
          );
        }
      });
      let obj = {};  //去除数组中Id相同的对象
      this.editworkdistributorsInfo = this.editworkdistributorsInfo.reduce((cur,next) => {
        obj[next.Id] ? "" : obj[next.Id] = true && cur.push(next);
        return cur;
      },[])
    },
    addHandleDataCheckAllChange(e) {
      console.log(e);
      //一级change事件
      this.addDataCheckAll = e;
      if (e == true) {
        this.addDataindeterminate = false;
        for (var i = 0; i < this.adddatadistributorsInfo.length; i++
        ) {
          //二级全选反选不确定
          this.adddatadistributorsInfo[i].SelectFlag = e;
          for (
            var j = 0, len1 = this.adddatadistributorsInfo[i].OperList.length;
            j < len1;
            j++
          ) {
            this.adddatadistributorsInfo[i].OperList[j].SelectFlag = e;
          }
        }
      } else {
        this.addDataindeterminate = false;
        for (let i = 0; i < this.adddatadistributorsInfo.length; i++) {
          //三级全选反选不确定
          this.adddatadistributorsInfo[i].SelectFlag = e;
          for (
            let j = 0, len1 = this.adddatadistributorsInfo[i].OperList.length;
            j < len1;
            j++
          ) {
            this.adddatadistributorsInfo[i].OperList[j].SelectFlag = e;
          }
        }
      }
    },

    addHandleWorkCheckAllChange(e) {
      console.log(e);
      //一级change事件
      this.addWorkCheckAll = e;
      if (e == true) {
        this.addWorkindeterminate = false;
        for (
          var i = 0, len = this.addworkdistributorsInfo.length;
          i < len;
          i++
        ) {
          //二级全选反选不确定
          this.addworkdistributorsInfo[i].SelectFlag = e;
          for (
            var j = 0, len1 = this.addworkdistributorsInfo[i].OperList.length;
            j < len1;
            j++
          ) {
            this.addworkdistributorsInfo[i].OperList[j].SelectFlag = e;
          }
        }
      } else {
        this.addWorkindeterminate = false;
        for (
          let i = 0, len = this.addworkdistributorsInfo.length;
          i < len;
          i++
        ) {
          //三级全选反选不确定
          this.addworkdistributorsInfo[i].SelectFlag = e;
          for (
            let j = 0, len1 = this.addworkdistributorsInfo[i].OperList.length;
            j < len1;
            j++
          ) {
            this.addworkdistributorsInfo[i].OperList[j].SelectFlag = e;
          }
        }
      }
    },
    handleWorkCheckedCountryAllChange(index, topId, e) {
      // console.log(e);
      // console.log(index);
      // console.log(this.addworkdistributorsInfo[index]);
      //二级change事件
      this.addworkdistributorsInfo[index].SelectFlag = e; //二级勾选后，子级全部勾选或者取消
      if (e == false) this.addworkdistributorsInfo[index].indeterminate = false; //去掉二级不确定状态
      var childrenArray = this.addworkdistributorsInfo[index].OperList;
      // console.log(childrenArray);
      if (childrenArray)
        for (var i = 0, len = childrenArray.length; i < len; i++)
          childrenArray[i].SelectFlag = e;
      this.getWorkIsCheckAll();
    },
    handleWorkCheckedCountryChange(topIndex, sonId, topId, e) {
      // console.log(topIndex, sonId, e);
      //三级change事件
      var childrenArray = this.addworkdistributorsInfo[topIndex].OperList;
      // console.log(childrenArray);
      var tickCount = 0,
        unTickCount = 0,
        len = childrenArray.length;
      for (var i = 0; i < len; i++) {
        if (sonId == childrenArray[i].Id) childrenArray[i].SelectFlag = e;
        if (childrenArray[i].SelectFlag == true) tickCount++;
        if (childrenArray[i].SelectFlag == false) unTickCount++;
      }
      if (tickCount == len) {
        //三级级全勾选
        this.addworkdistributorsInfo[topIndex].SelectFlag = true;
        this.addworkdistributorsInfo[topIndex].indeterminate = false;
      } else if (unTickCount == len) {
        //三级级全不勾选
        this.addworkdistributorsInfo[topIndex].SelectFlag = false;
        this.addworkdistributorsInfo[topIndex].indeterminate = false;
      } else {
        this.addworkdistributorsInfo[topIndex].SelectFlag = false;
        this.addworkdistributorsInfo[topIndex].indeterminate = true; //添加二级不确定状态
      }

      this.getWorkIsCheckAll();
    },
    getWorkIsCheckAll() {
      var tickCount = 0,
        unTickCount = 0,
        ArrLength = this.addworkdistributorsInfo.length;
      // console.log(ArrLength);
      for (var j = 0; j < ArrLength; j++) {
        //全选checkbox状态
        if (this.addworkdistributorsInfo[j].SelectFlag == true) tickCount++;
        if (this.addworkdistributorsInfo[j].SelectFlag == false) unTickCount++;
      }
      // console.log(tickCount, unTickCount);
      if (tickCount == ArrLength) {
        //二级全勾选
        this.addWorkCheckAll = true;
        this.addWorkindeterminate = false;
      } else if (unTickCount == ArrLength) {
        //二级全不勾选
        this.addWorkCheckAll = false;
        this.addWorkindeterminate = false;
      } else {
        this.addWorkCheckAll = false;
        this.addWorkindeterminate = true; //添加一级不确定状态
      }
    },

    editHandleWorkCheckAllChange(e) {
      // console.log(e);
      //一级change事件
      this.editWorkCheckAll = e;
      if (e == true) {
        this.editWorkindeterminate = false;
        for (
          var i = 0, len = this.editworkdistributorsInfo.length;
          i < len;
          i++
        ) {
          //二级全选反选不确定
          this.editworkdistributorsInfo[i].SelectFlag = e;
          for (
            var j = 0, len1 = this.editworkdistributorsInfo[i].OperList.length;
            j < len1;
            j++
          ) {
            this.editworkdistributorsInfo[i].OperList[j].SelectFlag = e;
          }
        }
      } else {
        this.editWorkindeterminate = false;
        for (
          let i = 0, len = this.editworkdistributorsInfo.length;
          i < len;
          i++
        ) {
          //三级全选反选不确定
          this.editworkdistributorsInfo[i].SelectFlag = e;
          for (
            let j = 0, len1 = this.editworkdistributorsInfo[i].OperList.length;
            j < len1;
            j++
          ) {
            this.editworkdistributorsInfo[i].OperList[j].SelectFlag = e;
          }
        }
      }
    },
    editHandleWorkCheckedCountryAllChange(index, topId, e) {
      //二级change事件
      this.editworkdistributorsInfo[index].SelectFlag = e; //二级勾选后，子级全部勾选或者取消
      if (e == false)
        this.editworkdistributorsInfo[index].indeterminate = false; //去掉二级不确定状态
      var childrenArray = this.editworkdistributorsInfo[index].OperList;
      // console.log(childrenArray);
      if (childrenArray)
        for (var i = 0, len = childrenArray.length; i < len; i++)
          childrenArray[i].SelectFlag = e;
      this.editWorkIsCheckAll();
    },
    editHandleWorkCheckedCountryChange(topIndex, sonId, topId, e) {
      console.log(topIndex, sonId, e);
      //三级change事件
      var childrenArray = this.editworkdistributorsInfo[topIndex].OperList;
      console.log(childrenArray);
      var tickCount = 0,
        unTickCount = 0,
        len = childrenArray.length;
      for (var i = 0; i < len; i++) {
        if (sonId == childrenArray[i].Id) childrenArray[i].SelectFlag = e;
        if (childrenArray[i].SelectFlag == true) tickCount++;
        if (childrenArray[i].SelectFlag == false) unTickCount++;
      }
      if (tickCount == len) {
        //三级级全勾选
        this.editworkdistributorsInfo[topIndex].SelectFlag = true;
        this.editworkdistributorsInfo[topIndex].indeterminate = false;
      } else if (unTickCount == len) {
        //三级级全不勾选
        this.editworkdistributorsInfo[topIndex].SelectFlag = false;
        this.editworkdistributorsInfo[topIndex].indeterminate = false;
      } else {
        this.editworkdistributorsInfo[topIndex].SelectFlag = false;
        this.editworkdistributorsInfo[topIndex].indeterminate = true; //添加二级不确定状态
      }
      this.editWorkIsCheckAll();
    },
    editWorkIsCheckAll() {
      var tickCount = 0,
        unTickCount = 0,
        ArrLength = this.editworkdistributorsInfo.length;
      console.log(ArrLength);
      for (var j = 0; j < ArrLength; j++) {
        //全选checkbox状态
        if (this.editworkdistributorsInfo[j].SelectFlag == true) tickCount++;
        if (this.editworkdistributorsInfo[j].SelectFlag == false) unTickCount++;
      }
      console.log(tickCount, unTickCount);
      if (tickCount == ArrLength) {
        //二级全勾选
        this.editWorkCheckAll = true;
        this.editWorkindeterminate = false;
      } else if (unTickCount == ArrLength) {
        //二级全不勾选
        this.editWorkCheckAll = false;
        this.editWorkindeterminate = false;
      } else {
        this.editWorkCheckAll = false;
        this.editWorkindeterminate = true; //添加一级不确定状态
      }
    },

    async submintEditWorkRolePermission() {
      let newObj = {};
      let newArr = [];
      this.editworkdistributorsInfo.forEach((item) => {
        console.log(item);
        for (var key in item) {
          if (
            key != "OrderNum" &&
            key != "SelectFlag" &&
            key != "isIndeterminate"
          ) {
            newObj[key] = item[key];
          }
          for (var keys in item.OperList) {
            if (item.OperList[keys].SelectFlag) {
              item.OperList[keys].SelectFlag = 1;
            } else {
              item.OperList[keys].SelectFlag = 0;
            }
          }
        }
        newArr.push(newObj);
        newObj = {};
        console.log(newArr);
        newArr.forEach((item) => {
          console.log(item);
          item.ResourceId = item.Id;
        });
      });
      newArr.forEach((item) => {
        for (var key in item) {
          if (key == "Id") {
            delete item[key];
          }
        }
      });
      this.editRoleSelectForm.RolePermissions = newArr;
      this.editRoleSelectForm.RoleInfo.SysScope = this.editPruceSelectValue
      this.editRoleSelectForm.RoleInfo.SysId = 2
      this.editRoleSelectForm.RoleInfo.PurchaseId = this.PurchaseId
      this.editRoleSelectForm.RoleInfo.RoleName = this.editRoleNameValue
      console.log(this.editRoleSelectForm,"@@@@");
      let res = await SaveRolePermission(this.editRoleSelectForm);
      console.log(res);
      if (res.data.Status !== 1) {
        return this.$message.error("保存失败！");
      }
      this.getSystemRoleList()
      this.$message.success("保存成功！");
      this.editDialogVisible = false;
    },

    async submintSaveWorkRolePermission() {
      let newObj = {};
      let newArr = [];
      console.log(this.addworkdistributorsInfo);
      this.addworkdistributorsInfo.forEach((item) => {
        console.log(item);
        for (var key in item) {
          if (
            key != "OrderNum" &&
            key != "SelectFlag" &&
            key != "isIndeterminate"
          ) {
            newObj[key] = item[key];
          }
          for (var keys in item.OperList) {
            if (item.OperList[keys].SelectFlag) {
              item.OperList[keys].SelectFlag = 1;
            } else {
              item.OperList[keys].SelectFlag = 0;
            }
          }
        }
        newArr.push(newObj);
        newObj = {};
        console.log(newArr);
        newArr.forEach((item) => {
          console.log(item);
          item.ResourceId = item.Id;
        });
      });
      newArr.forEach((item) => {
        for (var key in item) {
          if (key == "Id") {
            delete item[key];
          }
        }
      });
      this.addRoleSelectForm.RolePermissions = newArr;
      this.addRoleSelectForm.RoleInfo.SysId = 2;
      let res = await addRolePermission(this.addRoleSelectForm);
      console.log(res);
      if (res.data.Status !== 1) {
        return this.$message.error("新建失败！");
      }
      this.$message.success("新建成功！");
      this.addRoleSelectForm.RoleInfo.RoleName = "";
      this.addDataCheckAll = false;
      this.editDataindeterminate = false;
      this.addHandleDataCheckAllChange(false);
      this.addRoleSelectForm.RoleInfo.SysScope = "";
      this.getSystemRoleList();
      this.addDialogVisible = false;
      this.$router.go(0);
    },
    checkChange(value) {
      console.log(value);
    },
    async getSystemRoleList() {
      let res = await GetRolesList(this.queryInfo);
      console.log(res,'权限')
      this.roleTotal = res.data.TotalRowsCount;
      this.addRoleOptions = res.data.Entity;
      this.editRoleOptions = res.data.Entity;
      this.tableData = res.data.Entity;
    },

    addCheckSystemSelect(value) {
      this.systemValueForm.sysId = value;
      this.getSystemMenuList(this.systemValueForm);
    },
    async showEditDialog(item) {
      this.PurchaseId=item.PurchaseId
      this.i=""
      this.show=item.Flag
      item =JSON.parse(JSON.stringify(item))
      if (item.SysScope == "1") {
        this.editRoleSelectForm.RoleInfo.SysScope = 1;
      } else if (item.SysScope == "2") {
        this.editRoleSelectForm.RoleInfo.SysScope = 2;
      } else if (item.SysScope == "3") {
        this.editRoleSelectForm.RoleInfo.SysScope = 3;
      } else if (item.SysScope == "4") {
        this.editRoleSelectForm.RoleInfo.SysScope = 4;
      }
      this.editRoleSelectForm.RoleInfo.Id = item.Id;
      this.editRoleSelectForm.RoleInfo.SysId = this.activeNameEdit;
      this.editDialogVisible = true;
      this.editRoleNameValue = item.RoleName;
      this.checkRoleItemList.RoleId = item.Id;
      this.editHandleClick({name:"2"})
    },
    async editCheckSystemSelect(value) {
      console.log(value);
      const parame = {};
      parame.SysId = value;
      let res = await getRoleResources(parame);
      console.log(res);
      if (res.data.Status !== 1) return this.$message.error("获取失败！");
      res.data.Entity.resources.forEach((el) => {
        // el.SelectFlag = false;
        // el.isIndeterminate = false;
        let Operlist = [
          {
            Id: "1",
            SelectFlag: false,
          },
          {
            Id: "2",
            SelectFlag: false,
          },
          {
            Id: "3",
            SelectFlag: false,
          },
          {
            Id: "4",
            SelectFlag: false,
          },
          {
            Id: "5",
            SelectFlag: false,
          },
          {
            Id: "6",
            SelectFlag: false,
          },
          {
            Id: "7",
            SelectFlag: false,
          },
          {
            Id: "8",
            SelectFlag: false,
          },
          {
            Id: "9",
            SelectFlag: false,
          },
          {
            Id: "10",
            SelectFlag: false,
          },
          {
            Id: "11",
            SelectFlag: false,
          },
          {
            Id: "12",
            SelectFlag: false,
          },
        ];
        el.OperList = Operlist;
        res.data.Entity.resources.forEach((item) => {
          for (var key in item) {
            if (
              key == "ParentId" ||
              key == "ResourceType" ||
              key == "UrlAction"
            ) {
              delete item[key];
            }
          }
        });
      });
      this.distributorsInfo = res.data.Entity.resources;
    },
    async sumDelRole() {
      const parame = {};
      parame.roleId = this.delRoleItem.Id;
      const res = await delRolePermissions(parame);
      if (res.data.Status == 1) {
        this.delRoleDialog = false
        this.$message.success(res.data.Message);
        this.getSystemRoleList();
      } else {
        return this.$message.error(res.data.Message);
      }
    },
    showDelById(item) { // 打开删除角色弹框
      this.delRoleItem = item
      this.delRoleDialog = true
    },
    handleSelectionChange(item) {
      console.log(item);
    },
    // 监听pagesize（每页显示多少条数据）改变的事件
    handleSizeChange(newSize) {
      console.log(newSize);
      this.queryInfo.PageSize = newSize;
      this.getSystemRoleList();
    },
    // 监听pagenum（页码值）改变的事件
    handleCurrentChange(newPage) {
      console.log(newPage);
      this.queryInfo.PageIndex = newPage - 1;
      this.getSystemRoleList();
    },
  },
  computed: {},
  watch: {
    //深度 watched
    changeData: {
      handler(val, oldVal) {
        console.log(val);
        console.log(oldVal);
      },
      deep: true,
    },
  },
  mounted() {
  },
};
</script>
<style lang='scss' scoped>
@import "../../styles/mixin.scss";
.box {
  /deep/.el-tabs__item {
    color: #fff;
  }
  /deep/.el-tabs__item:hover {
    color: #00f6ff;
  }
  position: absolute;
  width: 100%;
  min-width: 1200px;
  overflow-y: hidden;
  /deep/.el-breadcrumb__inner a,
  /deep/.el-breadcrumb__inner.is-link {
    font-size: 15px;
    color: rgb(0, 246, 255);
    cursor: pointer;
  }
  /deep/.el-breadcrumb__item:last-child/deep/.el-breadcrumb__inner:hover {
    color: rgb(0, 246, 255);
    cursor: pointer;
  }
  .main {
    border-top: 0;
    height: 80%;
    margin: 0 20px;
    .search {
      display: flex;
      align-items: center;
      padding: 10px;
      width: 98%;
      margin: 0 auto;
      margin-top: 20px;
      margin-bottom: 20px;
      height: 25px;
      line-height: 25px;
      //color: #00f6ff;
      .search_mid {
        margin: 0 20px;
        span {
          margin: 0 5px;
          font-size: 14px;
          color: #666666;
          @include add-size($font_size_16);
        }
        .el-input {
          width: 150px;
          height: 40px;
          /deep/.el-input__inner {
            @include add-size($font_size_16);
            height: 40px;
          }
        }
      }
      .el-button {
      }
    }
    .primary {
      margin: 0 20px;
      height: 75%;
      width: 50%;
      overflow-y: auto;
      border-color: #031c65;
      /deep/ .el-table th {
        padding: 8px 0;
      }
      /deep/ .el-table--enable-row-transition .el-table__body td,
      .el-table .cell,
      .el-table__body {
        color: #606266!important;
        .el-button {
          background-color: transparent;
          border: 0;
        }
      }
      .block {
        position: fixed;
        bottom: 15px;
        .left {
          margin-left: 20px;
          height: 32px;
          line-height: 32px;
          float: left;
          span {
            font-weight: normal;
            color: #fff;
          }
          i {
            font-style: normal;
          }
        }
      }
      .el-table {
        /deep/td {
          padding: 8px 0;
        }
        .cell {
          .el-button--mini {
            padding: 5px 0;
          }
        }
      }
      &::-webkit-scrollbar {
        /*滚动条整体样式*/
        width: 5px; /*高宽分别对应横竖滚动条的尺寸*/
        height: 1px;
      }
      &::-webkit-scrollbar-thumb {
        /*滚动条里面小方块*/
        border-radius: 5px;
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: rgba(4, 244, 255, 0.8);
      }
      &::-webkit-scrollbar-track {
        /*滚动条里面轨道*/
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        background: rgba(4, 244, 255, 0.3);
      }
    }
  }
  .add-rights {
    .el-radio {
      color: #fff;
    }
    /deep/.el-dialog__title {
      color: #fff;
    }
    /deep/ .el-dialog__body {
      color: #fff;
    }
    /deep/.el-dialog {
      background-color: rgba(23, 63, 132, 0.9);
      .el-dialog__header {
        padding: 10px 20px 10px;
      }
      .el-tabs__nav-wrap:after {
        height: 0;
      }
      .el-dialog__body {
        padding: 5px 20px 20px;
        .check_role {
          margin-bottom: 5px;
          display: flex;
          .check_title {
            text-align: right;
            display: inline-block;
            width: 100px;
            line-height: 30px;
            height: 30px;
          }
          .el-input {
            width: 300px;
            .el-input__inner {
              height: 30px;
            }
          }
        }
        .check_sys {
          margin-bottom: 5px;
          display: flex;
          .check_title {
            line-height: 30px;
            height: 30px;
            text-align: right;
            display: inline-block;
            width: 100px;
          }
          .el-radio-group {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        .check_rights {
          border: 0;
          margin-bottom: 10px;
          display: flex;
          .check_title {
            text-align: right;
            display: inline-block;
            width: 100px;
          }
          .deliverySetting {
            padding: 0;
            position: relative;
            .deliverySetting-table {
              font-size: 14px;
              color: #fff;
              .table-head,
              .table-body {
                display: flex;
                padding: 0;
                .selection {
                  display: flex;
                  line-height: 36px;
                }
              }
              .table-head {
                display: flex;
                justify-content: space-around;
                width: 800px;
                text-align: center;
                height: 36px;
                align-items: center;
                background-color: transparent;
                .check_name {
                  display: flex;
                  justify-content: space-around;
                }
              }
              .table-body {
                color: #fff;
                .title_nav {
                  display: flex;
                  align-items: center;
                  .selection {
                    display: flex;
                    justify-content: center;
                  }
                  .title_left {
                    color: #fff;
                  }
                }
                .selection_all {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  .el-checkbox {
                    margin: 0 23px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  }
                }
              }
            }
          }
        }
        .check_ground {
          margin-bottom: 10px;
          display: flex;
          .check_title {
            line-height: 30px;
            height: 30px;
            text-align: right;
            display: inline-block;
            width: 100px;
          }
          .el-radio-group {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        .role-btn {
          margin-left: 100px;
        }
      }
    }
  }

  .edit-rights {
    .el-radio {
      color: #fff;
    }
    /deep/.el-dialog {
      border-radius: 10px;
      .el-dialog__header {
        border-radius: 10px 10px 0px 0px;
        background: #3388ff;
        .header-title {
          .title-name {
            display: inline-block;
            width: 10px;
            height: 10px;
            border: 4px solid #ffffff;
            border-radius: 50%;
            vertical-align: middle;
            margin-right: 4px;
            margin-bottom: 5px;
          }
          .title-age {
            vertical-align: top;
            display: inline-block;
            @include add-size($font_size_16);
            color: #ffffff;
          }
        }
        .el-dialog__headerbtn .el-dialog__close {
          color: #fff;
        }
      }
      .el-tabs__item {
        color: #3388FF;
        font-size: 18px;
      }
      .el-tabs__nav-wrap:after {
        height: 0;
      }
      .el-dialog__body {
        padding: 5px 20px 20px;
        .check_role {
          margin-bottom: 5px;
          display: flex;
          .check_title {
            text-align: right;
            display: inline-block;
            width: 100px;
            @include add-size($font_size_16);
            line-height: 40px;
            height: 40px;
          }
          .el-input {
            width: 300px;
            .el-input__inner {
              height: 40px;
              @include add-size($font_size_16);
            }
          }
        }
        .check_sys {
          margin-bottom: 5px;
          display: flex;
          .check_title {
            line-height: 30px;
            height: 30px;
            text-align: right;
            display: inline-block;
            width: 100px;
          }
          .el-radio-group {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        .check_rights {
          border: 0;
          margin-bottom: 10px;
          display: flex;
          .check_title {
            text-align: right;
            display: inline-block;
            width: 100px;
            font-size: 16px;
            line-height: 36px;
          }
          .el-table td {
            padding: 5px 0;
          }
          .deliverySetting {
            padding: 0;
            position: relative;
            .deliverySetting-table {
              font-size: 14px;
              .table-head,
              .table-body {
                display: flex;
                padding: 0;
                .selection {
                  display: flex;
                  line-height: 36px;
                }
              }
              .table-head {
                display: flex;
                justify-content: space-around;
                width: 800px;
                text-align: center;
                height: 36px;
                align-items: center;
                background-color: transparent;
                .check_name {
                  display: flex;
                  justify-content: space-around;
                  font-size: 16px;
                }
              }
              .table-body {
                .title_nav {
                  display: flex;
                  align-items: center;
                  .selection {
                    display: flex;
                    justify-content: center;
                  }
                  .title_left {
                    font-size: 16px;
                  }
                }
                .selection_all {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  .el-checkbox {
                    margin: 0 23px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  }
                }
              }
            }
          }
        }
        .check_ground {
          margin-bottom: 10px;
          display: flex;
          .check_title {
            line-height: 40px;
            height: 40px;
            font-size: 16px;
            text-align: right;
            display: inline-block;
            width: 120px;
            margin-right: 20px;
          }
          .el-radio__label {
            color: #666666;
            font-size: 16px;
          }
          .el-radio-group {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        .role-btn {
          margin-top: 20px;
          margin-bottom: 14px;
          text-align: center;
          button:nth-child(1) {
            width: 180px;
            background: #ffffff;
            border: 1px solid #3388ff;
            height: 50px;
            border-radius: 10px;
            font-size: 24px;
            color: #3388ff;
            margin-right: 20px;
          }
          button:nth-child(2) {
            width: 180px;
            border: 1px solid #3388ff;
            background: #3388ff;
            height: 50px;
            border-radius: 10px;
            font-size: 24px;
            color: #fff;
          }
        }
      }
    }
  }
}
.FinanceVoucher-dialog /deep/.el-dialog {
  border-radius: 10px;
  .el-dialog__header {
    border-radius: 10px 10px 0px 0px;
    background: #3388ff;
    margin-bottom: 20px;
    .header-title {
      .title-name {
        display: inline-block;
        width: 10px;
        height: 10px;
        border: 4px solid #ffffff;
        border-radius: 50%;
        vertical-align: middle;
        margin-right: 4px;
        margin-bottom: 4px;
      }
      .title-age {
        vertical-align: top;
        display: inline-block;
        @include add-size($font_size_16);
        color: #ffffff;
      }
    }
    .el-dialog__headerbtn .el-dialog__close {
      color: #fff;
    }
  }
  .btn {
    margin-top: 52px;
    text-align: center;
    button:nth-child(1) {
      width: 180px;
      background: #ffffff;
      border: 1px solid #3388ff;
      height: 50px;
      border-radius: 10px;
      font-size: 24px;
      color: #3388ff;
      margin-right: 20px;
    }
    button:nth-child(2) {
      width: 180px;
      //background: #ffffff;
      border: 1px solid #3388ff;
      background: #3388ff;
      height: 50px;
      border-radius: 10px;
      font-size: 24px;
      color: #fff;
    }
  }
}
.primary /deep/.el-table th {
  @include add-size($font_size_16);
  vertical-align: middle;
}
.primary /deep/.el-table__row {
  @include add-size($font_size_16);
  vertical-align: middle;
}
</style>
